import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import headerstyle from './header.module.scss'


const Header = () => {

    const data = useStaticQuery(graphql`
    query {
        site{
            siteMetadata {
                title
            }
        }
    }
    `)





    return (
        <div className={headerstyle.block}>
            <h1 className={headerstyle.title}> </h1>
            <nav>
                <ul className={headerstyle.ul}>
                    <li>
                    <Link className={headerstyle.link} activeClassName={headerstyle.isactive} to="/">Home</Link>
                    </li>
                    <li>
                    <a className={headerstyle.link} activeClassName={headerstyle.isactive} href="https://www.vidveda.com/aboutus">About Us</a>
                    </li>
                    <li>
                    <a className={headerstyle.link} activeClassName={headerstyle.isactive} href="https://www.vidveda.com/help">Contact</a>
                    </li>
                    <li> 
                    <a className={headerstyle.link} activeClassName={headerstyle.isactive} href="https://www.vidveda.com" target="_blank" rel="noopener noreferrer">Get Started</a>

                    </li>
                </ul>
            </nav>
            

        </div>
    )
}

export default Header
