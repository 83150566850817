import React from 'react'
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'

import Header from './header'
import Footer from './footer'
import layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return (
        <>
         <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
        <div className={layoutStyles.container}>

            <div className={layoutStyles.content}>
                <Header />
                {props.children}
            </div>
            
        </div>
        <Footer />
        </>
        
    )
}

export default Layout